@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    @apply bg-background text-gray-100 font-sans;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-heading;
  }
}

@layer components {
  .section-container {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24;
  }

  .section-title {
    @apply text-3xl md:text-4xl font-bold mb-8 text-primary;
  }

  .card {
    @apply bg-background-light rounded-lg p-3 sm:p-6 shadow-lg backdrop-blur-sm
           border border-gray-800 hover:border-primary/50 transition-all duration-300;
  }

  .button-primary {
    @apply bg-primary hover:bg-primary-dark text-white font-medium py-2 px-4 rounded-lg
           transition-all duration-300 transform hover:scale-105;
  }

  .button-outline {
    @apply border-2 border-primary text-primary hover:bg-primary hover:text-white
           font-medium py-2 px-4 rounded-lg transition-all duration-300;
  }
}

/* Scrollbar personalizado */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  @apply bg-background-dark;
}

::-webkit-scrollbar-thumb {
  @apply bg-primary/50 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-primary;
}

/* Animaciones de entrada */
.fade-up {
  animation: fadeUp 0.5s ease-out forwards;
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Shine effect para íconos sociales */
.social-icon-link {
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
}

.shine-effect-vertical {
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease;
}

.shine-active .shine-effect-vertical {
  animation: shineVertical 0.6s ease-in-out forwards;
}

@keyframes shineVertical {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

/* Resplandor del ícono activo */
.shine-active {
  filter: brightness(1.2);
  text-shadow: 0 0 10px currentColor;
}

/* Ajustes para menú móvil */
@media (max-width: 768px) {
  .social-icon-link {
    transition: opacity 0.3s ease-in-out;
  }
  
  .mobile-menu {
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }
}

/* Botpress Web Chat customization */
#bp-web-widget-container {
  z-index: 9999 !important;
  position: fixed !important;
  bottom: 24px !important;
  right: 24px !important;
}

/* Chat pointer animation */
.chat-pointer {
  position: fixed;
  pointer-events: none;
  z-index: 9999;
}

.chat-pointer-circle {
  position: absolute;
  width: 56px; /* Mismo tamaño que el botón de chat */
  height: 56px;
  border: 3px solid #3B82F6;
  border-radius: 50%;
  transform-origin: center;
  animation: moveCircle 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
  filter: drop-shadow(0 0 10px rgba(59, 130, 246, 0.5));
}

@keyframes moveCircle {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  20% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  80% {
    opacity: 1;
    transform: translate(calc(var(--pointer-move-x) - 50%), calc(var(--pointer-move-y) - 50%)) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(calc(var(--pointer-move-x) - 50%), calc(var(--pointer-move-y) - 50%)) scale(1.2);
  }
}

/* Añadir un efecto de brillo al botón cuando se hace clic */
.chat-button-highlight {
  animation: buttonPulse 0.5s ease-out;
}

@keyframes buttonPulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.4);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
}

/* Botpress styles */
.bpw-widget-btn {
  background-color: var(--bp-primary-color) !important;
  border-radius: 28px !important;
  padding: 0 20px !important;
  visibility: visible !important;
  opacity: 1 !important;
}

.bpw-layout {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
}

.bpw-header-container {
  background-color: var(--bp-primary-color) !important;
}

.bpw-chat-container {
  background-color: var(--bp-bg-color) !important;
}

.bpw-from-bot .bpw-chat-bubble {
  background-color: var(--bp-primary-color) !important;
  color: white !important;
}

.bpw-from-user .bpw-chat-bubble {
  background-color: var(--bp-primary-dark) !important;
  color: white !important;
}

.bpw-composer,
.bp-widget-composer {
  background-color: var(--bp-bg-color) !important;
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.bp-message {
  background-color: var(--bp-primary-color) !important;
  color: var(--bp-color) !important;
}

.bpHeaderContentTitle,
.bpw-header-container .bpHeaderContentTitle {
  content: "Bruno's AI Assistant bot" !important;
  display: block !important;
}

.bpMessageListMarqueeTitle {
  content: "Bruno's AI Assistant bot" !important;
  display: block !important;
}

/* Asegúrate de que los estilos se apliquen incluso después de la carga dinámica */
#bp-web-widget-container {
  z-index: 9999 !important;
}

#bp-web-widget-container .bpHeaderContentTitle,
#bp-web-widget-container .bpMessageListMarqueeTitle {
  content: "Bruno's AI Assistant bot" !important;
  display: block !important;
}

/* Spotlight effect */
.spotlight-effect {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  pointer-events: none;
  z-index: 9997;
  animation: spotlight 2s ease-in-out forwards;
}

@keyframes spotlight {
  0% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 50px 20px rgba(59, 130, 246, 0.3);
    transform: scale(1.2);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
    transform: scale(1);
  }
}

/* Estilos para el tooltip */
.tooltip-container {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 50;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.tooltip-arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%) rotate(45deg);
  width: 8px;
  height: 8px;
  background-color: #111827; /* bg-gray-900 */
}
